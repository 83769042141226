import React from 'react';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import DefaultProductDetail from './default';

function ProductGroupDetail(props) {
  const {product} = props;
  const [groupProducts, setGroupProducts] = React.useState([]);

  let {child = ''} = qs.parse(props.location.search);
  if (!child) {
    child = groupProducts[0]?.id;
  }
  const selectedProduct = groupProducts.find((p) => p.id === child);
  const setSelectedProduct = React.useCallback(
    (p) => {
      AppActions.navigate(
        `${props.location.pathname}?id=${props.product.id}&child=${p.id}`,
      );
    },
    [props.location.pathname, props.product.id],
  );

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const products = await JStorage.fetchAllDocuments(
          'product',
          {
            public: true,
            group_root_product: product.id,
          },
          product.is_session ? ['session.date'] : null,
          null,
          {anonymous: true},
        );
        setGroupProducts(products);
      } catch (ex) {
        console.warn('ProductGroupDetail.useEffect ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [product]);

  if (!selectedProduct) {
    return null;
  }

  return (
    <DefaultProductDetail
      {...props}
      product={selectedProduct}
      groupProducts={groupProducts}
      selectProductById={(id) => {
        const p = groupProducts.find((_p) => _p.id === id);
        if (p) {
          setSelectedProduct(p);
        }
      }}
    />
  );
}

export default withPageEntry(ProductGroupDetail);
