import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {Button, message, Tabs, Tooltip, Select} from 'antd';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import BreadcrumbBar from 'rev.sdk.js/Templates/ProductList/BreadcrumbBar';
import ProductVariants from 'rev.sdk.js/Components/ProductVariants';
import FilterMenu from 'rev.sdk.js/Templates/ProductList/FilterMenu';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import tracker from 'rev.sdk.js/Utils/Tracker';
import ProductExtraConfig from 'rev.sdk.js/Components/ProductExtraConfig';
import {Link as LinkIcon} from '@styled-icons/boxicons-regular/Link';
import {ArrowRight} from '@styled-icons/bootstrap/ArrowRight';
import Config from '../../../data.json';
import withPageEntry from '../../withPageEntry';
import {mapLineBreak} from '../../Utils/TextUtil';
import * as StockUtil from '../../Utils/StockUtil';
import {formatDateStr} from '../../Utils/TimeUtil';
import * as CustomBrowse from '../../Utils/CustomBrowse';
import * as AppActions from '../../AppActions';
import Carousel from '../../Components/Carousel';
import FixedRatioImage from '../../Components/FixedRatioImage';
import {getField as F, getLabel as L} from '../../Locale';
import ProductDetailCalendar from './ProductDetailCalendar';

function canAddToCart(product) {
  const {isOnSale} = StockUtil.getOnSaleInfo(product);
  const isSession = product.session && Object.keys(product.session).length > 0;
  const isAvailableSession =
    isSession &&
    new Date(
      `${product.session.date} ${product.session.start_time}`,
    ).getTime() > new Date().getTime();
  return isOnSale && (!isSession || isAvailableSession);
}

function ProductDetail(props) {
  const [locale] = useOutlet('locale');

  const {
    filterMenuType,
    renderCustomSection,
  } = CustomBrowse.getExtraProductDetailPage(props);

  const {
    pageContext: {
      collection = 'product',
      prefixPath = '/product',
      listViewPath = '/products',
      outlets = {categoryDisplayMap: 'categoryDisplayMap'},
      prebuiltProduct = null,
      prebuiltArticle = null,
      features = {cat: true},
    },
    product: passedProduct,
  } = props;
  const [activeSummaryTab, setActiveSummaryTab] = React.useState('intro');
  const product = prebuiltProduct || passedProduct;
  const [currItemConfig, setCurrItemConfig] = React.useState(null);
  const [currPrice, setCurrPrice] = React.useState(null);
  const [currOriginalPrice, setCurrOriginalPrice] = React.useState(null);
  const [article, setArticle] = React.useState(prebuiltArticle);
  const [imgIdx, setImgIdx] = React.useState(0);
  const [mobileFilterVisible, setMobileFilterVisible] = React.useState(false);
  const [selectedDt, setSelectedDt] = React.useState(null);
  const [extraConfigs, setExtraConfigs] = React.useState({});
  const [nIn1Products, setNIn1Products] = React.useState([]);

  const [user] = useOutlet('user');
  const showLoginModal = useOutletSetter('login-modal');
  const [dimension] = useOutlet('dimension');
  const isPrebuilt = !!prebuiltProduct;
  const mobile = dimension.rwd === 'mobile';

  React.useEffect(() => {
    async function fetchData() {
      try {
        const articleId = F(product, 'article');
        if (articleId) {
          setArticle(
            await JStorage.fetchOneDocument(
              'Article_Default',
              {
                id: articleId,
              },
              null,
              {anonymous: true},
            ),
          );
        }

        if (product.type === 'n_in_1') {
          setNIn1Products(
            await JStorage.fetchAllDocuments(
              'product',
              {
                _id: {
                  $in: [
                    ...(product.n_in_1_products || []).map((id) => ({
                      $oid: id,
                    })),
                  ],
                },
              },
              null,
              null,
              {anonymous: true},
            ),
          );
        }
      } catch (ex) {
        console.warn(ex);
      }
    }

    fetchData();
  }, [product, locale.lang]);

  React.useEffect(() => {
    function triggerTracker(product) {
      try {
        tracker.viewItem({
          name: 'product',
          item: product,
        });
      } catch (ex) {
        console.warn(ex);
      }
    }

    if (!isPrebuilt) {
      AppActions.setLoading(false);
      triggerTracker(product);
    } else {
      // waiting for tracker initial setup.
      setTimeout(() => triggerTracker(product), 2500);
    }
  }, [isPrebuilt, product]);

  const onNextConfig = React.useCallback((nextItemConfig, calcResp, extra) => {
    setCurrPrice(calcResp.amount);
    setCurrOriginalPrice(extra.original_price);
    setCurrItemConfig(nextItemConfig);
  }, []);

  async function copyShareUrl() {
    let _url = product.prebuilt_url
      ? `${Config.webHost}${product.prebuilt_url}`
      : AppActions.getReurl({
          title: encodeURIComponent(product.name),
          image: product.og_image || '',
          redirectUrl: `${Config.webHost}/product?id=${product.id}`,
        });

    try {
      await navigator.clipboard.writeText(_url);
      tracker.share({
        name: 'product',
        item: product,
        method: 'url',
      });
      message.success(`已複製分享連結`);
    } catch (err) {
      console.log(err);
      message.warn(`無法複製連結`);
    }
  }

  async function addToCart() {
    try {
      AppActions.setLoading(true);
      await Cart.addToCart(product.id, {...currItemConfig, extraConfigs});
      tracker.addToCart({product, quantity: currItemConfig.qty});
      message.success('成功');
    } catch (ex) {
      console.warn(ex);
      if (ex.response.error === 'item_length_limit') {
        message.error('超過購物車購買上限，請分批購買');
      } else {
        message.error('發生錯誤, 請至個人頁面按下「清除購物車」再重新嘗試');
      }
    } finally {
      AppActions.setLoading(false);
    }
  }

  if (!product) {
    return null;
  }

  let gallerySize = 450;
  if (dimension.innerWidth) {
    if (dimension.innerWidth < 450 + 20 * 2) {
      gallerySize = dimension.innerWidth - 20 * 2;
    }
  }

  const [saleStartDate, saleEndDate] = StockUtil.getStartEndDate(product);
  const {isOnSale, label: addToCartLabel} = StockUtil.getOnSaleInfo(product);
  const disabled =
    product.type === 'n_in_1'
      ? ![product, ...nIn1Products].every((p) => canAddToCart(p))
      : !canAddToCart(product);

  return (
    <Wrapper id="rev-ProductDetailPage">
      {product && (
        <Helmet>
          <meta charset="utf-8" />
          <meta property="og:title" content={product.name || ''} />
          <meta
            property="og:image"
            content={product.images ? product.images[0]?.expected_url : ''}
          />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <meta name="title" content={product.title || ''} />
          <meta name="description" content={product.intro || ''} />
          <meta
            name="image"
            content={product.images ? product.images[0]?.expected_url : ''}
          />
        </Helmet>
      )}

      {renderCustomSection('A', {product})}

      <div className="content">
        {features.cat && !mobile && (
          <div className="categories">
            <FilterMenu
              type={filterMenuType}
              cat={(product.labels && product.labels[0]) || ''}
              categories={'categories'}
              updateCat={(nextCat) => {
                AppActions.navigate(`${listViewPath}?cat=${nextCat}`);
              }}
              mobile={mobile}
            />
          </div>
        )}

        <div className="detail">
          {renderCustomSection('B', {product})}
          {product.labels && product.labels[0] && (
            <div className="breadcrumb" style={{marginTop: 35}}>
              <BreadcrumbBar
                cat={product.labels[0]}
                updateCat={(nextCat) => {
                  AppActions.navigate(`${listViewPath}?cat=${nextCat}`);
                }}
                categoryDisplayMap={outlets.categoryDisplayMap}
                instanceId={product.id}
                instanceDisplay={F(product, 'name')}
                updateInstance={(nextId) => {
                  AppActions.navigate(`${prefixPath}?id=${nextId}`);
                }}
              />
            </div>
          )}

          <TopSection>
            <Gallery dimension={dimension} size={gallerySize}>
              {product.images && product.images[imgIdx] && (
                <Carousel
                  pointCurrIdx={imgIdx}
                  width={gallerySize}
                  height={gallerySize}
                  data={product.images.map((i) => i.expected_url)}
                  renderPrev={null}
                  renderNext={null}
                  renderDots={null}
                  renderItem={({item}) => {
                    return (
                      <FixedRatioImage
                        image={item}
                        width="100%"
                        ratio={1}
                        mode="cover"
                        alt="product"
                        extraCss="border-radius: 15px;"
                      />
                    );
                  }}
                  disableSwipe
                />
              )}

              {product.images && (
                <MiniImageList>
                  {product.images.map((image, index) => (
                    <MiniImageItem
                      src={image.expected_url}
                      key={index}
                      alt="mini"
                      selected={index === imgIdx}
                      onClick={() => setImgIdx(index)}
                    />
                  ))}
                  <MiniImageItem css={{height: 0}} />
                  <MiniImageItem css={{height: 0}} />
                  <MiniImageItem css={{height: 0}} />
                </MiniImageList>
              )}
            </Gallery>

            <div className="gutter" />

            <Summary>
              {renderCustomSection('C', {product})}

              <h2>{product.name}</h2>

              {product.type === 'default' &&
                product.session &&
                Object.keys(product.session).length > 0 && (
                  <p className="session">
                    已選擇場次：
                    {product.session.date} {product.session.start_time}-
                    {product.session.end_time}
                  </p>
                )}

              {product.production_days > 0 && (
                <h4>
                  預計
                  {new Date(
                    new Date().getTime() + product.production_days * 8640000,
                  ).toLocaleDateString('sv')}
                  完成
                </h4>
              )}

              {product.type === 'group_item' &&
              product.session &&
              Object.keys(product.session).length > 0 ? (
                <>
                  <ProductDetailCalendar
                    size={260}
                    products={props.groupProducts}
                    selectedDt={selectedDt}
                    setSelectedDt={setSelectedDt}
                  />

                  {product.session && (
                    <p className="session">
                      已選擇場次：
                      {product.session.date} {product.session.start_time}-
                      {product.session.end_time}
                    </p>
                  )}

                  {Array.isArray(props.groupProducts) && (
                    <Select
                      value={''}
                      onChange={props.selectProductById}
                      style={{
                        width: 260,
                        marginBottom: 15,
                      }}>
                      <Select.Option value={''}>選擇其他組合</Select.Option>
                      {props.groupProducts
                        .filter((gp) => {
                          if (selectedDt) {
                            return (
                              gp.session.date === formatDateStr(selectedDt)
                            );
                          }
                          return true;
                        })
                        .map((gp) => {
                          const past =
                            gp.session && gp.session.start_time
                              ? new Date(
                                  `${gp.session.date} ${gp.session.start_time}`,
                                ).getTime() <= new Date().getTime()
                              : true;

                          return (
                            <Select.Option
                              key={gp.id}
                              value={gp.id}
                              disabled={past}>
                              {gp.session?.date} {gp.session?.start_time}-
                              {gp.session?.end_time}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  )}
                </>
              ) : (
                Array.isArray(props.groupProducts) && (
                  <Select
                    value={''}
                    onChange={props.selectProductById}
                    style={{width: '100%', marginBottom: 15}}>
                    <Select.Option value={''}>選擇其他組合</Select.Option>
                    {props.groupProducts.map((gp) => {
                      return (
                        <Select.Option key={gp.id} value={gp.id}>
                          {gp.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )
              )}

              {renderCustomSection('D', {product})}

              <Tabs
                className="tabs"
                activeKey={activeSummaryTab}
                onChange={setActiveSummaryTab}>
                <Tabs.TabPane tab={AppActions.L('intro')} key="intro">
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: mapLineBreak(F(product, 'intro')),
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={AppActions.L('spec')} key="spec">
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: mapLineBreak(F(product, 'spec')),
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={AppActions.L('remark')} key="remark">
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: mapLineBreak(F(product, 'remark')),
                    }}
                  />
                </Tabs.TabPane>
              </Tabs>

              {product.type === 'n_in_1' && (
                <div className="n-in-1-products">
                  <div className="title">* 包含以下項目:</div>
                  <div className="content">
                    {nIn1Products.map((p) => {
                      return (
                        <div key={p.id}>
                          <div>
                            <div>{p.name}</div>
                            <div>${p.price}</div>
                          </div>
                          <Button
                            type="link"
                            href={`/product/?id=${p.id}`}
                            target="_blank">
                            <ArrowRight size={20} />
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="variants">
                <ProductVariants
                  product={product}
                  onNextConfig={onNextConfig}
                />
              </div>

              {product.stock_type === 'period' && saleStartDate && saleEndDate && (
                <div className="stocks">
                  <InputField style={{justifyContent: 'flex-end'}}>
                    <label>供應開始時間: </label>
                    <div>{`${saleStartDate.toLocaleString()}`}</div>
                  </InputField>
                  <InputField style={{justifyContent: 'flex-end'}}>
                    <label>供應結束時間: </label>
                    <div>{`${saleEndDate.toLocaleString()}`}</div>
                  </InputField>
                </div>
              )}

              <InputField className="price">
                {(currOriginalPrice !== currPrice ||
                  product.original_price > 0) && (
                  <h3 className="text-line-through">
                    NT${currOriginalPrice || product.original_price}
                  </h3>
                )}
                <h2>NT${currPrice || product.price}</h2>
              </InputField>

              <LineSeperator />

              <ProductExtraConfig
                product={product}
                values={extraConfigs}
                setValues={setExtraConfigs}
              />

              <InputField
                style={{justifyContent: 'space-between'}}
                className="add-cart-button">
                <button
                  onClick={async () => {
                    if (!user) {
                      showLoginModal(true);
                      return;
                    }

                    await addToCart();
                  }}
                  disabled={disabled}
                  style={{
                    backgroundColor: disabled
                      ? 'lightgray'
                      : 'var(--primaryColor)',
                  }}>
                  {addToCartLabel}
                </button>
                <div className="gutter" />
                <button
                  onClick={async () => {
                    if (!user) {
                      showLoginModal(true);
                      return;
                    }

                    await addToCart();
                    AppActions.navigate('/checkout', {loading: true});
                  }}
                  disabled={disabled}
                  style={{
                    backgroundColor: disabled
                      ? 'lightgray'
                      : 'var(--secondColor)',
                  }}>
                  立即購買
                </button>
              </InputField>

              <InputField className="share-link">
                <Tooltip title={AppActions.L('share_link')}>
                  <button className="circle" onClick={copyShareUrl}>
                    <LinkIcon size={24} color={Config.themeColor} />
                  </button>
                </Tooltip>
              </InputField>
            </Summary>
          </TopSection>

          {renderCustomSection('E', {product})}

          <LineSeperator />

          {article && (
            <ArticlePreview
              dangerouslySetInnerHTML={{__html: mapLineBreak(article.html)}}
            />
          )}

          {renderCustomSection('F', {product})}
        </div>
      </div>

      {renderCustomSection('G', {product})}

      {features.cat && mobile && (
        <>
          <Backdrop
            visible={mobileFilterVisible}
            onClick={() => setMobileFilterVisible(false)}
          />

          <MobileFilter visible={mobileFilterVisible}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              {renderCustomSection('H')}
              <FilterMenu
                type={filterMenuType}
                cat={(product.labels && product.labels[0]) || ''}
                categories={'categories'}
                updateCat={(nextCat) => {
                  AppActions.navigate(`${listViewPath}?cat=${nextCat}`);
                }}
                mobile={mobile}
              />
              {renderCustomSection('I')}
            </div>
          </MobileFilter>

          <MobileMenuBtn
            className="rev-mobile-menu-btn"
            onClick={() => setMobileFilterVisible(!mobileFilterVisible)}>
            <Button type="primary">篩選</Button>
          </MobileMenuBtn>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 100px;
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    display: flex;

    & > .categories {
      padding-top: 12px;
      display: flex;
      flex-direction: column;

      & > h3 {
        border-bottom: 1px solid #ccc;
        padding: 4px 8px;
        align-self: center;
      }
    }

    & > .detail {
      flex: 1;
      padding: var(--basePadding);
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (max-width: 720px) {
    & > .content {
      flex-direction: column-reverse;

      & > .categories {
        & > ul {
          width: 100% !important;
        }
      }
    }
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;

  & > .gutter {
    width: 40px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Gallery = styled.div`
  width: ${(props) => props.size}px;
  margin-bottom: 20px;

  & > img:first-child {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    object-fit: contain;
  }
`;

const MiniImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 500px) {
    justify-content: space-around;
  }
`;

const MiniImageItem = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 4px;
  object-fit: contain;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  margin: 10px;
  cursor: pointer;

  :first-child,
  :nth-child(5n + 1) {
    margin-left: 0px;
  }

  :last-child,
  :nth-child(5n) {
    margin-right: 0px;
  }

  :hover,
  :active {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  @media (max-width: 500px) {
    width: 62px;
    height: 62px;
  }

  @media (max-width: 430px) {
    margin: 10px 4px;
    width: 58px;
    height: 58px;
  }

  ${(props) => props.css || ''}
`;

const Summary = styled.div`
  flex: 1;
  width: 100%;

  & > h2 {
    margin-bottom: 25px;
    color: var(--primaryColor);
  }

  & .session {
    margin-bottom: 15px;
    font-size: 0.95rem;
    color: var(--secondColor);
  }

  & .tabs {
    margin-bottom: 25px;
  }

  & .n-in-1-products {
    margin-bottom: 20px;

    & > .title {
      color: #888;
      margin-bottom: 10px;
    }

    & > .content {
      & > * {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        border-radius: 8px;
        border: 1px solid #ddd;
        margin-bottom: 10px;

        & > :first-child {
          flex: 1;
        }
      }
    }
  }

  & .variants {
    margin-bottom: 25px;
    & > div {
      align-items: flex-start;

      & > div > label {
        margin-bottom: 9px;
        font-weight: 500;
      }
    }
  }

  & .stock {
  }

  & .text {
    line-height: 1.86;
    color: #454545;
  }
`;

const InputField = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  & > label {
    margin-right: 10px;
  }

  & > h2 {
    font-weight: 500;
    font-size: 1.8rem;
  }

  & > button {
    flex: 1;
    border-radius: 25px;
    border: 0px;
    padding: 10px;
    color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;

    :hover,
    :active {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }

  & button.circle {
    flex: 0;
    margin-top: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;

    :hover,
    :active {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }

  & .gutter {
    width: 10px;
  }

  & .text-line-through {
    color: #cbcbcb;
    margin-right: 10px;
    text-decoration: line-through;
  }
`;

const LineSeperator = styled.section`
  border-top: 1px solid #eee;
  margin: 20px 0;
`;

const ArticlePreview = styled.div`
  white-space: pre;

  & img {
    max-width: 100%;
  }

  white-space: pre-wrap;
`;

const MobileMenuBtn = styled.div`
  position: fixed;
  top: 84px;
  right: 0px;
`;

const MobileFilter = styled.div`
  z-index: 99;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  overflow: auto;
  height: 100vh;
  transform: ${(props) =>
    props.visible ? 'translateX(0px)' : 'translateX(-300px)'};
  transition: 180ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
`;

const Backdrop = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 180ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;

export default withPageEntry(ProductDetail);
